<template>
  <section>
      <div class="mt-2 smallFolders" v-show="!showFolders">
        <b-nav vertical class="mt-2 inbox-nav">
          <div v-if="sefosEnabled">
            <b-nav-text>
              <b-avatar size="30px"><i class="fas fa-user-alt"></i></b-avatar>
            </b-nav-text>
        
            <b-nav-item
              :to="{
                name: 'Messages.Inbox',
                params: { email: user.information.email },
              }"
              exact
              @click.prevent="closeFolders"
              v-b-tooltip.hover
              :title="user.information.email"
            >
              <i class="fal fa-inbox"></i>
              <b-badge v-if="user.new_message_inbox != 0">{{
                user.new_message_inbox
              }}</b-badge>
            </b-nav-item>
            <b-nav-item
            :to="{
              name: 'Messages.Drafts',
              params: { email: user.information.email },
            }"
            exact
           @click.prevent="closeFolders"
            v-b-tooltip.hover
            :title="user.information.email"
          >
            <i class="fal fa-pen-to-square"></i>
            <b-badge v-if="user.drafts != 0">{{
              user.drafts
            }}</b-badge>
          </b-nav-item>
            <b-nav-item
              :to="{
                name: 'Messages.Sent',
                params: { email: user.information.email },
              }"
              exact
              v-b-tooltip.hover
              :title="$t('MESSAGES.SENT')"
              @click.prevent="closeFolders"
              ><i class="fal fa-paper-plane"></i>
            </b-nav-item>
            <b-nav-item
              :to="{
                name: 'Messages.External',
                params: { email: user.information.email },
              }"
              v-b-tooltip.hover
              :title="$t('MESSAGES.EXTERNAL')"
              exact
              @click.prevent="closeFolders"
              ><i class="fal fa-link"></i
            ></b-nav-item>
            <b-nav-item
              :to="{
                name: 'Messages.Notification',
                params: { email: user.information.email }
              }"
              v-b-tooltip.hover
              :title="$t('NOTIFICATIONS.TITLE')"
              exact
              @click.prevent="closeFolders"
            >
              <i class="fal fa-bell"></i>
              <b-badge v-if="user.new_notification != 0">{{ user.new_notification }}</b-badge>
            </b-nav-item>
          </div>
                    
          <div v-if="user.delegatedboxes && sefosEnabled">
            <div
              v-for="(item, index) in user.delegatedboxes.items"
              :key="delegatedKey(index)"
              :value="item"
            >
              <b-nav-text
                class="clickable"
                @click="collapseDelegatedBox(item)"
              >
                <b-avatar
                  size="30px"
                  variant="secondary"
                  :text="avatarInitials(item)"
                ></b-avatar>
              </b-nav-text>
  
              <b-collapse v-model="item.collapse">
             
                <b-nav-item
                  :to="{
                    name: 'Messages.Inbox',
                    query: { user_uuid: item.user_uuid },
                    params: { email: item.email },
                  }"
                  @click.prevent="closeFolders"
                  exact
                  v-b-tooltip.hover
                  :title="item.email"
                >
                  <i class="fal fa-inbox"></i>
                  <b-badge v-if="item.count != 0">{{ item.count }}</b-badge>
                </b-nav-item>
                <b-nav-item
                :to="{
                  name: 'Messages.Drafts',
                  query: { user_uuid: item.user_uuid },
                  params: { email: item.email },
                }"
                @click.prevent="closeFolders"
                exact
                v-b-tooltip.hover
                :title="item.email"
              >
                <i class="fal fa-pen-to-square"></i>
                <b-badge v-if="item.drafts != 0">{{ item.drafts }}</b-badge>
              </b-nav-item>
                <b-nav-item
                  :to="{
                    name: 'Messages.Sent',
                    query: { user_uuid: item.user_uuid },
                    params: { email: item.email },
                  }"
                  @click.prevent="closeFolders"
                  exact
                  v-b-tooltip.hover
                  :title="$t('MESSAGES.SENT')"
                  ><i class="fal fa-paper-plane"></i>
                </b-nav-item>
                <b-nav-item
                  :to="{
                    name: 'Messages.External',
                    query: { user_uuid: item.user_uuid },
                    params: { email: item.email },
                  }"
                  @click.prevent="closeFolders"
                  exact
                  v-b-tooltip.hover
                  :title="$t('MESSAGES.EXTERNAL')"
                  ><i class="fal fa-link"></i
                ></b-nav-item>
                <b-nav-item
                  :to="{
                    name: 'Messages.Notification',
                    query: { user_uuid: item.user_uuid },
                    params: { email: item.email },
                  }"
                  @click.prevent="closeFolders"
                  exact
                  v-b-tooltip.hover
                  :title="item.email"
                >
                  <i class="fal fa-bell"></i>
                  <b-badge>{{ item.count }}</b-badge>
                </b-nav-item>
              </b-collapse>
            </div>
          </div>
          
          <div
            v-for="(item, index) in user.functionboxes.items"
            :key="index"
            :value="item"
          >
            <div v-if="ShowFunctionbox(item)">
              <b-nav-text class="clickable" @click="collapse(item)">
                <b-avatar
                  size="30px"
                  variant="secondary"
                  :text="avatarInitials(item)"
                ></b-avatar>
              </b-nav-text>
              <b-collapse v-model="item.collapse">
              
                <b-nav-item
                  :to="{
                    name: 'Messages.Inbox',
                    query: { functionbox_uuid: item.functionbox_uuid },
                    params: { email: item.email },
                  }"
                 @click.prevent="closeFolders"
                  exact
                  v-b-tooltip.hover
                  :title="item.email"
                >
                  <i class="fal fa-inbox"></i>
                  <b-badge v-if="item.count != 0">{{ item.count }}</b-badge>
                </b-nav-item>
                <b-nav-item
                :to="{
                  name: 'Messages.Drafts',
                  query: { functionbox_uuid: item.functionbox_uuid },
                  params: { email: item.email },
                }"
               @click.prevent="closeFolders"
                exact
                v-b-tooltip.hover
                :title="item.email"
              >
                <i class="fal fa-pen-to-square"></i>
                <b-badge v-if="item.drafts != 0">{{ item.drafts }}</b-badge>
              </b-nav-item>
                <b-nav-item
                  :to="{
                    name: 'Messages.Sent',
                    query: { functionbox_uuid: item.functionbox_uuid },
                    params: { email: item.email },
                  }"
                  @click.prevent="closeFolders"
                  v-b-tooltip.hover
                  :title="$t('MESSAGES.SENT')"
                  exact
                  ><i class="fal fa-paper-plane"></i>
                </b-nav-item>
                <b-nav-item
                  :to="{
                    name: 'Messages.External',
                    query: { functionbox_uuid: item.functionbox_uuid },
                    params: { email: item.email },
                  }"
                  @click.prevent="closeFolders"
                  v-b-tooltip.hover
                  :title="$t('MESSAGES.EXTERNAL')"
                  exact
                  ><i class="fal fa-link"></i
                ></b-nav-item>
                <b-nav-item
                  :to="{
                    name: 'Messages.Notification',
                    query: { functionbox_uuid: item.functionbox_uuid },
                    params: { email: item.email },
                  }"
                  @click.prevent="closeFolders"
                  exact
                  v-b-tooltip.hover
                  :title="item.email"
                >
                  <i class="fal fa-bell"></i>
                  <b-badge>{{ item.count }}</b-badge>
                </b-nav-item>
              </b-collapse>
            </div>
          </div>
        </b-nav>
      </div>
  
      <div class="mt-2" v-show="showFolders">
        <b-nav vertical tabs class="mt-2 inbox-nav">
          <div class="email-text" v-if="sefosEnabled">
            <b-nav-text class="mb-0 pb-0">
              &nbsp;<b-avatar size="30px" variant="secondary" class="mb-1"
                ><i class="fas fa-user-alt"></i
              ></b-avatar>
              {{ user.information.email }}
            </b-nav-text>
          </div>
          <div class="email-text" v-if="sefosEnabled">            
            <b-nav-item
            :to="{
              name: 'Messages.Inbox',
              params: { email: user.information.email },
            }"
            exact
           @click.prevent="closeFolders"
          >
            <i class="fal fa-inbox"></i>&nbsp;&nbsp;{{ $t("MESSAGES.INBOX") }}
          </b-nav-item>
          <b-badge v-if="user.new_message_inbox != 0">{{user.new_message_inbox}}</b-badge>          
          </div>
          <div class="email-text" v-if="sefosEnabled">
            <b-nav-item
              :to="{
                name: 'Messages.Drafts',
                params: { email: user.information.email },
              }"
              exact
             @click.prevent="closeFolders"
            >
              <i class="fal fa-pen-to-square"></i>&nbsp;&nbsp;{{ $t("MESSAGES.DRAFTS") }}
            </b-nav-item>
            <b-badge v-if="user.drafts != 0">{{user.drafts}}</b-badge>   
          </div>
          <div class="email-text" v-if="sefosEnabled">
            <b-nav-item
              :to="{
                name: 'Messages.Sent',
                params: { email: user.information.email },
              }"
              exact
              @click.prevent="closeFolders"
            >
              <i class="fal fa-paper-plane"></i>&nbsp;&nbsp;{{
                $t("MESSAGES.SENT")
              }}</b-nav-item
            >
          </div>          
          <div class="email-text" v-if="sefosEnabled">
            <b-nav-item
              :to="{
                name: 'Messages.External',
                params: { email: user.information.email },
              }"
              exact
             @click.prevent="closeFolders"
            >
              <i class="fal fa-link"></i>&nbsp;&nbsp;{{
                $t("MESSAGES.EXTERNAL")
              }}</b-nav-item
            >
          </div>
          <div class="email-text" v-if="sefosEnabled">
            <b-nav-item
              :to="{
                name: 'Messages.Notification',
                params: { email: user.information.email },
              }"
              exact
              @click.prevent="closeFolders"
            >
              <i class="fal fa-bell"></i>&nbsp;&nbsp;{{ $t("NOTIFICATIONS.TITLE") }}
            </b-nav-item>
            <b-badge v-if="user.new_notification != 0">{{ user.new_notification }}</b-badge>
          </div>
  
          <div v-if="user.delegatedboxes && sefosEnabled">
            <div
              v-for="(item, index) in user.delegatedboxes.items"
              :key="delegatedKey(index)"
              :value="item"
            >
              <div class="email-text">
                <b-nav-text
                  class="mb-0 pb-0 clickable"
                  @click="collapseDelegatedBox(item)"
                  v-b-tooltip.hover
                  :title="item.name"
                >
                  &nbsp;<b-avatar
                    size="30px"
                    variant="secondary"
                    class="mb-1"
                    :text="avatarInitials(item)"
                  ></b-avatar>
                  {{ item.name }}
                  <b-badge v-if="!item.collapse && item.count != 0">{{
                    item.count
                  }}</b-badge>
                  <i
                    class="fal collapse-icon "
                    v-bind:class="{
                      'fa-chevron-up': !item.collapse,
                      'fa-chevron-down': item.collapse,
                    }"
                  ></i>
                </b-nav-text>
                <b-collapse v-model="item.collapse">
                 
                  <div class="email-text">
                    <b-nav-item
                      :to="{
                        name: 'Messages.Inbox',
                        query: { user_uuid: item.user_uuid },
                        params: { email: item.email },
                      }"
                      exact
                      @click.prevent="closeFolders"
                    >
                      <i class="fal fa-inbox"></i>&nbsp;&nbsp;{{
                        $t("MESSAGES.INBOX")
                      }}
                    </b-nav-item>
                    <b-badge v-if="item.count != 0">{{ item.count }}</b-badge>
                  </div>
                  <div class="email-text">
                    <b-nav-item
                      :to="{
                        name: 'Messages.Drafts',
                        query: { user_uuid: item.user_uuid },
                        params: { email: item.email },
                      }"
                      exact
                      @click.prevent="closeFolders"
                    >
                      <i class="fal fa-pen-to-square"></i>&nbsp;&nbsp;{{
                        $t("MESSAGES.DRAFTS")
                      }}
                    </b-nav-item>
                    <b-badge v-if="item.drafts != 0">{{ item.drafts }}</b-badge>
                  </div>
                  <b-nav-item
                    :to="{
                      name: 'Messages.Sent',
                      query: { user_uuid: item.user_uuid },
                      params: { email: item.email },
                    }"
                    exact
                    @click.prevent="closeFolders"
                  >
                    <i class="fal fa-paper-plane"></i>&nbsp;&nbsp;{{
                      $t("MESSAGES.SENT")
                    }}</b-nav-item
                  >
                  <b-nav-item
                    :to="{
                      name: 'Messages.External',
                      query: { user_uuid: item.user_uuid },
                      params: { email: item.email },
                    }"
                    exact
                    @click="closeFolders"
                  >
                    <i class="fal fa-link"></i>&nbsp;&nbsp;{{
                      $t("MESSAGES.EXTERNAL")
                    }}</b-nav-item
                  >
                  <div class="email-text">
                  <b-nav-item
                      :to="{
                        name: 'Messages.Notification',
                        query: { user_uuid: item.user_uuid },
                        params: { email: item.email },
                      }"
                      exact
                      @click.prevent="closeFolders"
                    >
                    <i class="fal fa-bell"></i>&nbsp;&nbsp;{{
                      $t("NOTIFICATIONS.TITLE")
                    }}
                  </b-nav-item>
                  <b-badge v-if="item.notification_count != 0">{{ item.notification_count }}</b-badge>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
  
          <div
            v-for="(item, index) in user.functionboxes.items"
            :key="index"
            :value="item"
          >
            <div v-if="ShowFunctionbox(item)">
              <div class="email-text">
                <b-nav-text class="mb-0 pb-0 clickable" @click="collapse(item)" v-b-tooltip.hover 
                  :title="item.name">
                  &nbsp;<b-avatar
                    size="30px"
                    variant="secondary"
                    class="mb-1"
                    :text="avatarInitials(item)"
                  ></b-avatar>
                  {{ item.name }}
                  <b-badge v-if="!item.collapse && item.count != 0">{{
                    item.count
                  }}</b-badge>
                  <i
                    class="fal collapse-icon "
                    v-bind:class="{
                      'fa-chevron-up': !item.collapse,
                      'fa-chevron-down': item.collapse,
                    }"
                  ></i>
                </b-nav-text>
              </div>
              <b-collapse v-model="item.collapse">
                <div class="email-text">
                    <b-nav-item
                    :to="{
                      name: 'Messages.Inbox',
                      query: { functionbox_uuid: item.functionbox_uuid },
                      params: { email: item.email },
                    }"
                    exact
                    @click.prevent="closeFolders"
                  >
                    <i class="fal fa-inbox"></i>&nbsp;&nbsp;{{
                      $t("MESSAGES.INBOX")
                    }}
                  </b-nav-item>                 
                  <b-badge v-if="item.count != 0">{{ item.count }}</b-badge>
                </div>
                <div class="email-text">
                  <b-nav-item
                    :to="{
                      name: 'Messages.Drafts',
                      query: { functionbox_uuid: item.functionbox_uuid },
                      params: { email: item.email },
                    }"
                    exact
                    @click.prevent="closeFolders"
                  >
                    <i class="fal fa-pen-to-square"></i>&nbsp;&nbsp;{{
                      $t("MESSAGES.DRAFTS")
                    }}
                  </b-nav-item>
                  <b-badge v-if="item.drafts != 0">{{ item.drafts }}</b-badge>
                </div>
                <div class="email-text">
                <b-nav-item
                  :to="{
                    name: 'Messages.Sent',
                    query: { functionbox_uuid: item.functionbox_uuid },
                    params: { email: item.email },
                  }"
                  exact
                  @click.prevent="closeFolders"
                >
                  <i class="fal fa-paper-plane"></i>&nbsp;&nbsp;{{
                    $t("MESSAGES.SENT")
                  }}</b-nav-item
                >
                </div>
                <div class="email-text">
                <b-nav-item
                  :to="{
                    name: 'Messages.External',
                    query: { functionbox_uuid: item.functionbox_uuid },
                    params: { email: item.email },
                  }"
                  exact
                  @click.prevent="closeFolders"
                >
                  <i class="fal fa-link"></i>&nbsp;&nbsp;{{
                    $t("MESSAGES.EXTERNAL")
                  }}</b-nav-item
                >
                </div>
                <div class="email-text">
                  <b-nav-item
                    :to="{
                      name: 'Messages.Notification',
                      query: { functionbox_uuid: item.functionbox_uuid },
                      params: { email: item.email },
                    }"
                    exact
                    @click.prevent="closeFolders"
                  >
                    <i class="fal fa-bell"></i>&nbsp;&nbsp;{{
                      $t("NOTIFICATIONS.TITLE")
                    }}
                  </b-nav-item>
                  <b-badge v-if="item.notification_count != 0">{{ item.notification_count }}</b-badge>
                </div>
              </b-collapse>
            </div>
          </div>

        </b-nav>
      </div>
  </section>
</template>
<script>
export default {
  props: {
    functionbox_uuid: {
      default: "",
      type: String,
    },
    user_uuid: {
      default: "",
      type: String,
    },
    sefosEnabled: {
      type: Boolean,
      default: true,
    },
    showFolders: {
      type: Boolean,
      default: true,
    },
    isMobile: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      redrawIndex: 0,
      items: [],
      selectedIndex: 0,
      totalItems: 0,
      state: 1,
    };
  },
  methods: {
    collapse(item) {
      let self = this;
      this.$http
        .post(this.user.hostname + "/user/functionbox/collapse", {
          account_id: this.user.information.id,
          functionbox_uuid: item.functionbox_uuid,
          collapsed: !item.collapse,
        })
        .then(function () {
          for (let ix = 0; ix < self.user.functionboxes.items.length; ix++) {
            if (self.user.functionboxes.items[ix].email == item.email) {
              self.user.functionboxes.items[ix].collapse = !item.collapse;
            }
          }
        })
        .catch(function () {});
    },
    collapseDelegatedBox(item) {
      let self = this;
      this.$http
        .post(this.user.hostname + "/user/delegatedbox/collapse", {
          account_id: this.user.information.id,
          user_uuid: item.user_uuid,
          collapsed: !item.collapse,
        })
        .then(function () {
          for (let ix = 0; ix < self.user.delegatedboxes.items.length; ix++) {
            if (self.user.delegatedboxes.items[ix].email == item.email) {
              self.user.delegatedboxes.items[ix].collapse = !item.collapse;
            }
          }
        })
        .catch(function () {});
    },
    ShowFunctionbox(item) {
      if (!this.sefosEnabled) {
        if (item.fax_enabled || item.sdk_enabled) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    closeFolders() {
      this.$emit("closeFolders");
    },
    delegatedKey(index) {
      return "delegated_" + index;
    },
    avatarInitials(item) {
      return (
        item.name.charAt(0).toUpperCase() +
        item.name.slice(item.name.length - 1).toUpperCase()
      );
    },
  },
  computed: {
  },
  watch: {
  },
  mounted: function() {    
  },
};
</script>
<style></style>
