var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showFolders,
            expression: "!showFolders",
          },
        ],
        staticClass: "mt-2 smallFolders",
      },
      [
        _c(
          "b-nav",
          { staticClass: "mt-2 inbox-nav", attrs: { vertical: "" } },
          [
            _vm.sefosEnabled
              ? _c(
                  "div",
                  [
                    _c(
                      "b-nav-text",
                      [
                        _c("b-avatar", { attrs: { size: "30px" } }, [
                          _c("i", { staticClass: "fas fa-user-alt" }),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-nav-item",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: {
                          to: {
                            name: "Messages.Inbox",
                            params: { email: _vm.user.information.email },
                          },
                          exact: "",
                          title: _vm.user.information.email,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.closeFolders.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fal fa-inbox" }),
                        _vm.user.new_message_inbox != 0
                          ? _c("b-badge", [
                              _vm._v(_vm._s(_vm.user.new_message_inbox)),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "b-nav-item",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: {
                          to: {
                            name: "Messages.Drafts",
                            params: { email: _vm.user.information.email },
                          },
                          exact: "",
                          title: _vm.user.information.email,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.closeFolders.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fal fa-pen-to-square" }),
                        _vm.user.drafts != 0
                          ? _c("b-badge", [_vm._v(_vm._s(_vm.user.drafts))])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "b-nav-item",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: {
                          to: {
                            name: "Messages.Sent",
                            params: { email: _vm.user.information.email },
                          },
                          exact: "",
                          title: _vm.$t("MESSAGES.SENT"),
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.closeFolders.apply(null, arguments)
                          },
                        },
                      },
                      [_c("i", { staticClass: "fal fa-paper-plane" })]
                    ),
                    _c(
                      "b-nav-item",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: {
                          to: {
                            name: "Messages.External",
                            params: { email: _vm.user.information.email },
                          },
                          title: _vm.$t("MESSAGES.EXTERNAL"),
                          exact: "",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.closeFolders.apply(null, arguments)
                          },
                        },
                      },
                      [_c("i", { staticClass: "fal fa-link" })]
                    ),
                    _c(
                      "b-nav-item",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: {
                          to: {
                            name: "Messages.Notification",
                            params: { email: _vm.user.information.email },
                          },
                          title: _vm.$t("NOTIFICATIONS.TITLE"),
                          exact: "",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.closeFolders.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fal fa-bell" }),
                        _vm.user.new_notification != 0
                          ? _c("b-badge", [
                              _vm._v(_vm._s(_vm.user.new_notification)),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.user.delegatedboxes && _vm.sefosEnabled
              ? _c(
                  "div",
                  _vm._l(_vm.user.delegatedboxes.items, function (item, index) {
                    return _c(
                      "div",
                      { key: _vm.delegatedKey(index), attrs: { value: item } },
                      [
                        _c(
                          "b-nav-text",
                          {
                            staticClass: "clickable",
                            on: {
                              click: function ($event) {
                                return _vm.collapseDelegatedBox(item)
                              },
                            },
                          },
                          [
                            _c("b-avatar", {
                              attrs: {
                                size: "30px",
                                variant: "secondary",
                                text: _vm.avatarInitials(item),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-collapse",
                          {
                            model: {
                              value: item.collapse,
                              callback: function ($$v) {
                                _vm.$set(item, "collapse", $$v)
                              },
                              expression: "item.collapse",
                            },
                          },
                          [
                            _c(
                              "b-nav-item",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                attrs: {
                                  to: {
                                    name: "Messages.Inbox",
                                    query: { user_uuid: item.user_uuid },
                                    params: { email: item.email },
                                  },
                                  exact: "",
                                  title: item.email,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.closeFolders.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fal fa-inbox" }),
                                item.count != 0
                                  ? _c("b-badge", [_vm._v(_vm._s(item.count))])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-nav-item",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                attrs: {
                                  to: {
                                    name: "Messages.Drafts",
                                    query: { user_uuid: item.user_uuid },
                                    params: { email: item.email },
                                  },
                                  exact: "",
                                  title: item.email,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.closeFolders.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fal fa-pen-to-square",
                                }),
                                item.drafts != 0
                                  ? _c("b-badge", [_vm._v(_vm._s(item.drafts))])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-nav-item",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                attrs: {
                                  to: {
                                    name: "Messages.Sent",
                                    query: { user_uuid: item.user_uuid },
                                    params: { email: item.email },
                                  },
                                  exact: "",
                                  title: _vm.$t("MESSAGES.SENT"),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.closeFolders.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fal fa-paper-plane" })]
                            ),
                            _c(
                              "b-nav-item",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                attrs: {
                                  to: {
                                    name: "Messages.External",
                                    query: { user_uuid: item.user_uuid },
                                    params: { email: item.email },
                                  },
                                  exact: "",
                                  title: _vm.$t("MESSAGES.EXTERNAL"),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.closeFolders.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fal fa-link" })]
                            ),
                            _c(
                              "b-nav-item",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                attrs: {
                                  to: {
                                    name: "Messages.Notification",
                                    query: { user_uuid: item.user_uuid },
                                    params: { email: item.email },
                                  },
                                  exact: "",
                                  title: item.email,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.closeFolders.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fal fa-bell" }),
                                _c("b-badge", [_vm._v(_vm._s(item.count))]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._l(_vm.user.functionboxes.items, function (item, index) {
              return _c("div", { key: index, attrs: { value: item } }, [
                _vm.ShowFunctionbox(item)
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-nav-text",
                          {
                            staticClass: "clickable",
                            on: {
                              click: function ($event) {
                                return _vm.collapse(item)
                              },
                            },
                          },
                          [
                            _c("b-avatar", {
                              attrs: {
                                size: "30px",
                                variant: "secondary",
                                text: _vm.avatarInitials(item),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-collapse",
                          {
                            model: {
                              value: item.collapse,
                              callback: function ($$v) {
                                _vm.$set(item, "collapse", $$v)
                              },
                              expression: "item.collapse",
                            },
                          },
                          [
                            _c(
                              "b-nav-item",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                attrs: {
                                  to: {
                                    name: "Messages.Inbox",
                                    query: {
                                      functionbox_uuid: item.functionbox_uuid,
                                    },
                                    params: { email: item.email },
                                  },
                                  exact: "",
                                  title: item.email,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.closeFolders.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fal fa-inbox" }),
                                item.count != 0
                                  ? _c("b-badge", [_vm._v(_vm._s(item.count))])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-nav-item",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                attrs: {
                                  to: {
                                    name: "Messages.Drafts",
                                    query: {
                                      functionbox_uuid: item.functionbox_uuid,
                                    },
                                    params: { email: item.email },
                                  },
                                  exact: "",
                                  title: item.email,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.closeFolders.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fal fa-pen-to-square",
                                }),
                                item.drafts != 0
                                  ? _c("b-badge", [_vm._v(_vm._s(item.drafts))])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-nav-item",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                attrs: {
                                  to: {
                                    name: "Messages.Sent",
                                    query: {
                                      functionbox_uuid: item.functionbox_uuid,
                                    },
                                    params: { email: item.email },
                                  },
                                  title: _vm.$t("MESSAGES.SENT"),
                                  exact: "",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.closeFolders.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fal fa-paper-plane" })]
                            ),
                            _c(
                              "b-nav-item",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                attrs: {
                                  to: {
                                    name: "Messages.External",
                                    query: {
                                      functionbox_uuid: item.functionbox_uuid,
                                    },
                                    params: { email: item.email },
                                  },
                                  title: _vm.$t("MESSAGES.EXTERNAL"),
                                  exact: "",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.closeFolders.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fal fa-link" })]
                            ),
                            _c(
                              "b-nav-item",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                attrs: {
                                  to: {
                                    name: "Messages.Notification",
                                    query: {
                                      functionbox_uuid: item.functionbox_uuid,
                                    },
                                    params: { email: item.email },
                                  },
                                  exact: "",
                                  title: item.email,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.closeFolders.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fal fa-bell" }),
                                _c("b-badge", [_vm._v(_vm._s(item.count))]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            }),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showFolders,
            expression: "showFolders",
          },
        ],
        staticClass: "mt-2",
      },
      [
        _c(
          "b-nav",
          { staticClass: "mt-2 inbox-nav", attrs: { vertical: "", tabs: "" } },
          [
            _vm.sefosEnabled
              ? _c(
                  "div",
                  { staticClass: "email-text" },
                  [
                    _c(
                      "b-nav-text",
                      { staticClass: "mb-0 pb-0" },
                      [
                        _vm._v("\n             "),
                        _c(
                          "b-avatar",
                          {
                            staticClass: "mb-1",
                            attrs: { size: "30px", variant: "secondary" },
                          },
                          [_c("i", { staticClass: "fas fa-user-alt" })]
                        ),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.user.information.email) +
                            "\n          "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.sefosEnabled
              ? _c(
                  "div",
                  { staticClass: "email-text" },
                  [
                    _c(
                      "b-nav-item",
                      {
                        attrs: {
                          to: {
                            name: "Messages.Inbox",
                            params: { email: _vm.user.information.email },
                          },
                          exact: "",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.closeFolders.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fal fa-inbox" }),
                        _vm._v(
                          "  " + _vm._s(_vm.$t("MESSAGES.INBOX")) + "\n        "
                        ),
                      ]
                    ),
                    _vm.user.new_message_inbox != 0
                      ? _c("b-badge", [
                          _vm._v(_vm._s(_vm.user.new_message_inbox)),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.sefosEnabled
              ? _c(
                  "div",
                  { staticClass: "email-text" },
                  [
                    _c(
                      "b-nav-item",
                      {
                        attrs: {
                          to: {
                            name: "Messages.Drafts",
                            params: { email: _vm.user.information.email },
                          },
                          exact: "",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.closeFolders.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fal fa-pen-to-square" }),
                        _vm._v(
                          "  " +
                            _vm._s(_vm.$t("MESSAGES.DRAFTS")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm.user.drafts != 0
                      ? _c("b-badge", [_vm._v(_vm._s(_vm.user.drafts))])
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.sefosEnabled
              ? _c(
                  "div",
                  { staticClass: "email-text" },
                  [
                    _c(
                      "b-nav-item",
                      {
                        attrs: {
                          to: {
                            name: "Messages.Sent",
                            params: { email: _vm.user.information.email },
                          },
                          exact: "",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.closeFolders.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fal fa-paper-plane" }),
                        _vm._v("  " + _vm._s(_vm.$t("MESSAGES.SENT"))),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.sefosEnabled
              ? _c(
                  "div",
                  { staticClass: "email-text" },
                  [
                    _c(
                      "b-nav-item",
                      {
                        attrs: {
                          to: {
                            name: "Messages.External",
                            params: { email: _vm.user.information.email },
                          },
                          exact: "",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.closeFolders.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fal fa-link" }),
                        _vm._v("  " + _vm._s(_vm.$t("MESSAGES.EXTERNAL"))),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.sefosEnabled
              ? _c(
                  "div",
                  { staticClass: "email-text" },
                  [
                    _c(
                      "b-nav-item",
                      {
                        attrs: {
                          to: {
                            name: "Messages.Notification",
                            params: { email: _vm.user.information.email },
                          },
                          exact: "",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.closeFolders.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fal fa-bell" }),
                        _vm._v(
                          "  " +
                            _vm._s(_vm.$t("NOTIFICATIONS.TITLE")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm.user.new_notification != 0
                      ? _c("b-badge", [
                          _vm._v(_vm._s(_vm.user.new_notification)),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.user.delegatedboxes && _vm.sefosEnabled
              ? _c(
                  "div",
                  _vm._l(_vm.user.delegatedboxes.items, function (item, index) {
                    return _c(
                      "div",
                      { key: _vm.delegatedKey(index), attrs: { value: item } },
                      [
                        _c(
                          "div",
                          { staticClass: "email-text" },
                          [
                            _c(
                              "b-nav-text",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                staticClass: "mb-0 pb-0 clickable",
                                attrs: { title: item.name },
                                on: {
                                  click: function ($event) {
                                    return _vm.collapseDelegatedBox(item)
                                  },
                                },
                              },
                              [
                                _vm._v("\n                 "),
                                _c("b-avatar", {
                                  staticClass: "mb-1",
                                  attrs: {
                                    size: "30px",
                                    variant: "secondary",
                                    text: _vm.avatarInitials(item),
                                  },
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.name) +
                                    "\n                "
                                ),
                                !item.collapse && item.count != 0
                                  ? _c("b-badge", [_vm._v(_vm._s(item.count))])
                                  : _vm._e(),
                                _c("i", {
                                  staticClass: "fal collapse-icon",
                                  class: {
                                    "fa-chevron-up": !item.collapse,
                                    "fa-chevron-down": item.collapse,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-collapse",
                              {
                                model: {
                                  value: item.collapse,
                                  callback: function ($$v) {
                                    _vm.$set(item, "collapse", $$v)
                                  },
                                  expression: "item.collapse",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "email-text" },
                                  [
                                    _c(
                                      "b-nav-item",
                                      {
                                        attrs: {
                                          to: {
                                            name: "Messages.Inbox",
                                            query: {
                                              user_uuid: item.user_uuid,
                                            },
                                            params: { email: item.email },
                                          },
                                          exact: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.closeFolders.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fal fa-inbox",
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(_vm.$t("MESSAGES.INBOX")) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                    item.count != 0
                                      ? _c("b-badge", [
                                          _vm._v(_vm._s(item.count)),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "email-text" },
                                  [
                                    _c(
                                      "b-nav-item",
                                      {
                                        attrs: {
                                          to: {
                                            name: "Messages.Drafts",
                                            query: {
                                              user_uuid: item.user_uuid,
                                            },
                                            params: { email: item.email },
                                          },
                                          exact: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.closeFolders.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fal fa-pen-to-square",
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(_vm.$t("MESSAGES.DRAFTS")) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                    item.drafts != 0
                                      ? _c("b-badge", [
                                          _vm._v(_vm._s(item.drafts)),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: {
                                        name: "Messages.Sent",
                                        query: { user_uuid: item.user_uuid },
                                        params: { email: item.email },
                                      },
                                      exact: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.closeFolders.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fal fa-paper-plane",
                                    }),
                                    _vm._v(
                                      "  " + _vm._s(_vm.$t("MESSAGES.SENT"))
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: {
                                        name: "Messages.External",
                                        query: { user_uuid: item.user_uuid },
                                        params: { email: item.email },
                                      },
                                      exact: "",
                                    },
                                    on: { click: _vm.closeFolders },
                                  },
                                  [
                                    _c("i", { staticClass: "fal fa-link" }),
                                    _vm._v(
                                      "  " + _vm._s(_vm.$t("MESSAGES.EXTERNAL"))
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "email-text" },
                                  [
                                    _c(
                                      "b-nav-item",
                                      {
                                        attrs: {
                                          to: {
                                            name: "Messages.Notification",
                                            query: {
                                              user_uuid: item.user_uuid,
                                            },
                                            params: { email: item.email },
                                          },
                                          exact: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.closeFolders.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", { staticClass: "fal fa-bell" }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.$t("NOTIFICATIONS.TITLE")
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                    item.notification_count != 0
                                      ? _c("b-badge", [
                                          _vm._v(
                                            _vm._s(item.notification_count)
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._l(_vm.user.functionboxes.items, function (item, index) {
              return _c("div", { key: index, attrs: { value: item } }, [
                _vm.ShowFunctionbox(item)
                  ? _c(
                      "div",
                      [
                        _c(
                          "div",
                          { staticClass: "email-text" },
                          [
                            _c(
                              "b-nav-text",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                staticClass: "mb-0 pb-0 clickable",
                                attrs: { title: item.name },
                                on: {
                                  click: function ($event) {
                                    return _vm.collapse(item)
                                  },
                                },
                              },
                              [
                                _vm._v("\n                 "),
                                _c("b-avatar", {
                                  staticClass: "mb-1",
                                  attrs: {
                                    size: "30px",
                                    variant: "secondary",
                                    text: _vm.avatarInitials(item),
                                  },
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.name) +
                                    "\n                "
                                ),
                                !item.collapse && item.count != 0
                                  ? _c("b-badge", [_vm._v(_vm._s(item.count))])
                                  : _vm._e(),
                                _c("i", {
                                  staticClass: "fal collapse-icon",
                                  class: {
                                    "fa-chevron-up": !item.collapse,
                                    "fa-chevron-down": item.collapse,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-collapse",
                          {
                            model: {
                              value: item.collapse,
                              callback: function ($$v) {
                                _vm.$set(item, "collapse", $$v)
                              },
                              expression: "item.collapse",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "email-text" },
                              [
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: {
                                        name: "Messages.Inbox",
                                        query: {
                                          functionbox_uuid:
                                            item.functionbox_uuid,
                                        },
                                        params: { email: item.email },
                                      },
                                      exact: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.closeFolders.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fal fa-inbox" }),
                                    _vm._v(
                                      "  " +
                                        _vm._s(_vm.$t("MESSAGES.INBOX")) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                item.count != 0
                                  ? _c("b-badge", [_vm._v(_vm._s(item.count))])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "email-text" },
                              [
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: {
                                        name: "Messages.Drafts",
                                        query: {
                                          functionbox_uuid:
                                            item.functionbox_uuid,
                                        },
                                        params: { email: item.email },
                                      },
                                      exact: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.closeFolders.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fal fa-pen-to-square",
                                    }),
                                    _vm._v(
                                      "  " +
                                        _vm._s(_vm.$t("MESSAGES.DRAFTS")) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                item.drafts != 0
                                  ? _c("b-badge", [_vm._v(_vm._s(item.drafts))])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "email-text" },
                              [
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: {
                                        name: "Messages.Sent",
                                        query: {
                                          functionbox_uuid:
                                            item.functionbox_uuid,
                                        },
                                        params: { email: item.email },
                                      },
                                      exact: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.closeFolders.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fal fa-paper-plane",
                                    }),
                                    _vm._v(
                                      "  " + _vm._s(_vm.$t("MESSAGES.SENT"))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "email-text" },
                              [
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: {
                                        name: "Messages.External",
                                        query: {
                                          functionbox_uuid:
                                            item.functionbox_uuid,
                                        },
                                        params: { email: item.email },
                                      },
                                      exact: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.closeFolders.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fal fa-link" }),
                                    _vm._v(
                                      "  " + _vm._s(_vm.$t("MESSAGES.EXTERNAL"))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "email-text" },
                              [
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: {
                                        name: "Messages.Notification",
                                        query: {
                                          functionbox_uuid:
                                            item.functionbox_uuid,
                                        },
                                        params: { email: item.email },
                                      },
                                      exact: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.closeFolders.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fal fa-bell" }),
                                    _vm._v(
                                      "  " +
                                        _vm._s(_vm.$t("NOTIFICATIONS.TITLE")) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                item.notification_count != 0
                                  ? _c("b-badge", [
                                      _vm._v(_vm._s(item.notification_count)),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }